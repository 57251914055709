<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personHealthLedger">教职工健康情况管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <el-row>
        <el-form-item label="日期" prop="queryTime">
          <el-date-picker
            size="mini"
            v-model="queryModel.queryTime"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属学校" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
          ></el-select-tree
          >&nbsp;&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="健康情况" prop="healthStatus">
          <el-select
            v-model="queryModel.healthStatus"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 220px"
          >
            <el-option
              v-for="health in healthData"
              :key="health.value"
              :label="health.name"
              :value="health.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="姓名" prop="personName">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.personName"
          ></el-input>
        </el-form-item>
        <el-form-item label="上报情况" prop="isReport">
          <el-select
            v-model="queryModel.isReport"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option value="9" label="全部"></el-option>
            <el-option value="0" label="未上报"></el-option>
            <el-option value="1" label="已上报"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="jobType">
          <el-select
            v-model="queryModel.jobType"
            filterable
            placeholder="请选择"
            size="mini"
            style="width: 100px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="1" label="班主任"></el-option>
            <el-option value="2" label="普通老师"></el-option>
            <el-option value="3" label="监管人员"></el-option>
            <el-option value="4" label="其他职工"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打卡位置" prop="address">
          <el-select
            v-model="queryModel.address"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option value label="全部"></el-option>
            <el-option value="0" label="市内"></el-option>
            <el-option value="1" label="市外省内"></el-option>
            <el-option value="2" label="省外"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="fever">
          <el-checkbox v-model="queryModel.fever">是否体温异常</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="handleQuery"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXls"
        >导出健康信息台账</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="handleExportDetail"
        >导出健康信息明细表</el-button
      >
      <el-button
        type="warning"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsWC"
        >导出外出信息台账</el-button
      >
      <el-button
        type="danger"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsTWYC"
        >导出体温异常信息台账</el-button
      >
      <el-button
        type="danger"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsZZYC"
        >导出症状异常信息台账</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsHJR"
        >导出(荆州区)学校健康监测日报表</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsHJJKJC"
        >导出(荆州区)学校健康监测信息表</el-button
      >
    </el-row>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXlsDAYNumber"
        >导出"日报告"情况统计表</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      style="min-height: 400px"
      :height="tableHeight"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="100"
      ></el-table-column>
      <el-table-column prop="name" label="名称" width="150"></el-table-column>
      <el-table-column
        prop="personHealthLedger.temperature"
        label="记录温度"
        width="150"
      >
        <template slot-scope="{ row }">
          <span v-if="row.personHealthLedger.temperature"
            >{{ row.personHealthLedger.temperature }}
            <el-link
              type="primary"
              :underline="false"
              @click="handleEditTemperature(row)"
              v-show="batchEditVisible"
              >编辑</el-link
            ></span
          ></template
        >
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="校园"
        width="220"
      ></el-table-column>
      <el-table-column prop="healthStatusN" label="健康情况" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.healthStatusN
          }}</span>
          <span v-else>未提交</span>
        </template>
      </el-table-column>
      <el-table-column prop="familyStatusN" label="家庭健康情况" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.familyStatusN
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isGoto"
        sort-by="is_goto"
        label="是否前往疫区"
        width="150"
      >
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.isGoto == 1 ? "是" : "否"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isTouch" label="是否接触高危人员" width="150">
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.isTouch == 1 ? "是" : "否"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="180"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{ row.personHealthLedger.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="上报日期" width="180">
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.createTime
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="上报位置" width="180">
        <template slot-scope="{ row }">
          <span v-if="row.isUpHealth">{{
            row.personHealthLedger.address
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="100">
        <template slot-scope="{ row }">
          <el-button size="mini" type="success" @click="showHistoryList(row)"
            >历史轨迹</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personHealthLedger-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></personHealthLedger-detail>
    <teacherHealthLedger-list-detalExport
      v-if="showModal2"
      :businessKey="businessKey"
      :title="modalTitle2"
      @close="onDetailModalClose2"
    ></teacherHealthLedger-list-detalExport>
    <el-dialog
      :visible.sync="showMapDialog"
      title="历史位置"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-amap
        ref="map"
        vid="vehicleMap"
        :center="pointPosition"
        :zoom="15"
        style="width: 100%; height: 400px"
      >
        <el-amap-marker
          :position="pointPosition"
          :title="selectedRow.licensePlateNumber"
        ></el-amap-marker>
      </el-amap>
      <el-input
        :value="pointPosition[0] + ',' + pointPosition[1]"
        :readonly="true"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMapDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showHistoryDialog"
      title="历史轨迹"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="1000px"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <vehicle-history-list
        ref="vehicleHistoryList"
        :personId="selectedRow.id"
      ></vehicle-history-list>
    </el-dialog>
    <el-dialog
      :visible.sync="showModal"
      title="修改"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align: left"
      :close-on-click-modal="false"
      :loading="downloadLoading"
    >
      <el-form ref="form" :model="formModel" :label-width="'130px'">
        <el-form-item label="温度" prop="temperature">
          <el-input v-model="formModel.temperature"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">关闭</el-button>
        <el-button type="primary" @click="handleUpdateTemperature"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import personHealthLedgerApi from "@/api/student/personHealthLedger";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import permissionApi from "@/api/sys/permission";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import pageUtil from "@/utils/page";
import VehicleHistoryList from "./vehicleHistory-list";
import teacherHealthLedgerListDetalExport from "./teacherHealthLedger-list-detalExport";

export default {
  name: "BaseTeacherHealthLedgerList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        queryTime: [{ required: true, message: "请选择时间", trigger: "blur" }],
      },
      queryModel: {
        personName: "",
        healthStatus: "",
        companyId: "",
        subordinate: true,
        queryTime: "",
        popedom: "3,4,9",
        jobType: "",
        address: "",
        fever: false,
        isReport: "9",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      showModal2: false,
      modalTitle: "",
      modalTitle2: "",
      businessKey: "",
      healthData: [],
      companyResult: [],
      tableHeight: 400,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      downloadLoading: false,
      jobsData: [],
      showMapDialog: false,
      pointPosition: [112.240222, 30.337053],
      selectedRow: {
        licensePlateNumber: "",
        deviceNo: "",
      },
      showHistoryDialog: false,
      formModel: { id: "", temperature: "" },
      batchEditVisible: false,
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("catalogName", "健康情况");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.healthData = jsonData.data;
    });

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    permissionApi
      .hasPermission("post", "/personDeviceLog/update")
      .then((response) => {
        this.batchEditVisible = response.data.result;
      });

    this.loadTree();
    this.setDefaultTime();

    setTimeout(() => {
      //45为分页栏的高度
      //页面高度-列表上面的高度-分页栏高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    }, 1000);
  },
  methods: {
    showMap(row) {
      this.pointPosition = [
        parseFloat(row.longitude),
        parseFloat(row.latitude),
      ];
      this.selectedRow = row;
      this.showMapDialog = true;
    },
    showHistoryList(row) {
      this.selectedRow = row;
      this.showHistoryDialog = true;

      this.$nextTick(() => {
        this.$refs.vehicleHistoryList.getCurrentMonthFirst();
        this.$refs.vehicleHistoryList.changePage(1);
      });
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personName", self.queryModel.personName);
      formData.append("healthStatus", self.queryModel.healthStatus);
      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("queryTime", self.queryModel.queryTime);
      formData.append("address", self.queryModel.address);
      formData.append("fever", self.queryModel.fever);
      formData.append("isReport", self.queryModel.isReport);

      //3,4,9
      var popedom = self.queryModel.popedom;

      var jobType = "";
      //1班主任 2普通老师 3监管人员 4其他职工
      if (self.queryModel.jobType == "1") {
        popedom = "3";
        jobType = "1";
      } else if (self.queryModel.jobType == "2") {
        popedom = "3";
        jobType = "0";
      } else if (self.queryModel.jobType == "3") {
        popedom = "9";
      } else if (self.queryModel.jobType == "4") {
        popedom = "3";
        jobType = "2";
      }
      formData.append("popedom", popedom);
      formData.append("jobType", jobType);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personHealthLedgerApi
        .pageListNew(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleExportDetail() {
      this.modalTitle2 = "导出健康信息明细表";
      this.businessKey = "";
      this.showModal2 = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleQuery() {
      var self = this;
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.changePage(1);
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      //this.queryModel.companyId = "";
      this.queryModel.queryTime = "";
      this.queryModel.subordinate = false;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    setDefaultTime() {
      var self = this;
      var date = new Date();
      var month = parseInt(date.getMonth() + 1);
      var nowTime = date.getFullYear() + "-" + month + "-" + date.getDate();

      self.queryModel.queryTime = nowTime;
    },
    exportXls() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          formData.append("pageIndex", self.pageIndex);
          formData.append("pageSize", self.pageSize);

          formData.append("personName", self.queryModel.personName);
          formData.append("healthStatus", self.queryModel.healthStatus);
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          //3,4,9
          var popedom = self.queryModel.popedom;

          var jobType = "";
          //1班主任 2普通老师 3监管人员 4其他职工
          if (self.queryModel.jobType == "1") {
            popedom = "3";
            jobType = "1";
          } else if (self.queryModel.jobType == "2") {
            popedom = "3";
            jobType = "0";
          } else if (self.queryModel.jobType == "3") {
            popedom = "9";
          } else if (self.queryModel.jobType == "4") {
            popedom = "3";
            jobType = "2";
          }
          formData.append("popedom", popedom);
          formData.append("jobType", jobType);

          personHealthLedgerApi.exportXls(formData).then(function (response) {
            var jsonData = response.data;

            self.downloadLoading = false;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                type: "success",
                showClose: true,
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 0,
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message,
              });
            }
          });
        }
      });
    },
    exportXlsWC() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          personHealthLedgerApi.exportXlsWC(formData).then(function (response) {
            var jsonData = response.data;

            self.downloadLoading = false;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                type: "success",
                showClose: true,
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 0,
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message,
              });
            }
          });
        }
      });
    },
    exportXlsTWYC() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          personHealthLedgerApi
            .exportXlsTWYC(formData)
            .then(function (response) {
              var jsonData = response.data;

              self.downloadLoading = false;
              self.loading = false;

              if (jsonData.result) {
                self.$message({
                  type: "success",
                  showClose: true,
                  message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                  dangerouslyUseHTMLString: true,
                  duration: 0,
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        }
      });
    },
    exportXlsZZYC() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          personHealthLedgerApi
            .exportXlsZZYC(formData)
            .then(function (response) {
              var jsonData = response.data;

              self.downloadLoading = false;
              self.loading = false;

              if (jsonData.result) {
                self.$message({
                  type: "success",
                  showClose: true,
                  message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                  dangerouslyUseHTMLString: true,
                  duration: 0,
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        }
      });
    },
    exportXlsHJR() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          personHealthLedgerApi
            .exportXlsHJR(formData)
            .then(function (response) {
              var jsonData = response.data;

              self.downloadLoading = false;
              self.loading = false;

              if (jsonData.result) {
                self.$message({
                  type: "success",
                  showClose: true,
                  message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                  dangerouslyUseHTMLString: true,
                  duration: 0,
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        }
      });
    },
    exportXlsHJJKJC() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          personHealthLedgerApi
            .exportXlsHJJKJC(formData)
            .then(function (response) {
              var jsonData = response.data;

              self.downloadLoading = false;
              self.loading = false;

              if (jsonData.result) {
                self.$message({
                  type: "success",
                  showClose: true,
                  message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                  dangerouslyUseHTMLString: true,
                  duration: 0,
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        }
      });
    },
    exportXlsDAYNumber() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", "3");

          personHealthLedgerApi
            .exportXlsDAYNumber(formData)
            .then(function (response) {
              var jsonData = response.data;

              self.downloadLoading = false;
              self.loading = false;

              if (jsonData.result) {
                self.$message({
                  type: "success",
                  showClose: true,
                  message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                  dangerouslyUseHTMLString: true,
                  duration: 0,
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        }
      });
    },

    handleEditTemperature(record) {
      var self = this;
      self.formModel.id = record.personHealthLedger.id;
      self.formModel.temperature = record.personHealthLedger.temperature;
      self.showModal = true;
    },
    handleUpdateTemperature(record) {
      var self = this;
      self.loading = true;

      personHealthLedgerApi.update(this.formModel).then(function (response) {
        self.loading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          self.showModal = false;
          self.changePage(self.pageIndex);
          self.formModel.id = "";
          self.formModel.temperature = "";
          self.$message({
            type: "success",
            message: "修改成功!",
          });
        } else {
          self.$message.success("修改失败!");
        }
      });
    },
  },
  mounted: function () {
    //this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree,
    "vehicle-history-list": VehicleHistoryList,
    "teacherHealthLedger-list-detalExport": teacherHealthLedgerListDetalExport,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>